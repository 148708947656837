@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.connect-button__container > .iekbcc0 {
  flex-direction: column-reverse !important;
  justify-content: stretch;
  width: 100%;
}

.connect-button__container > .iekbcc0 button {
  justify-content: center !important;
}